import { useEffect, useState } from "react";
import { Login } from "./components/login/Login";
import { Home } from "./components/dashboard/Home";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./util/protectedRoute";
import { getItem } from "./util/localStorage";
import { useNavigate } from "react-router-dom";
import { PageNotFound } from "./components/Notfound.js";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import { useLocation } from "react-router";
import Footer from "./common/Footer";
// CSS
import "./App.css";
import "./assets/css/style.css";
import { MyContext } from "./config/config";

import { CallList } from "./components/callList/CallList.jsx";

import { AvailibilitySchedule } from "./components/avalibility/AvailibilitySchedule";
import { Profile } from "./components/profile/AdminProfile";
import { Appointments } from "./components/appointments/appointments";
import { ChangePassword } from "./components/changePassword/ChangePassword";
import Configuration from "./components/configuration/Config.jsx";
import KnowledgeBase from "./components/knowledgeBase/KnowledgeBase.jsx";
import Agent from "./components/agent/Agent.jsx";
import setupAxiosInterceptors from "./service/axiosConfig.js";
import PhoneNumbers from "./components/phoneNumbers/PhoneNumbers.jsx";


function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [affiliateRequest, setAffiliateRequest] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [openLoad, setOpenLoad] = useState(false);
  const [editProfile, setEditProfile] = useState(false)
  useEffect(() => {
    if (getItem("safe_tek_admin_token")) {
    } else {
      navigate("/");
    }
    setupAxiosInterceptors();
  }, []);

  return (
    <MyContext.Provider value={{ affiliateRequest, setAffiliateRequest, filterType, setFilterType, openLoad, setOpenLoad, editProfile, setEditProfile }}>
      <>
        {location.pathname !== "/" ? (
          <>
            <Header />
            <Sidebar />
          </>
        ) : (
          <></>
        )}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/Home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />

        
          <Route
            path="/call-list"
            element={
              <ProtectedRoute>
                <CallList />
              </ProtectedRoute>}
          >
          </Route>
       
          <Route
            path="/availibility"
            element={
              <ProtectedRoute>
                <AvailibilitySchedule />
              </ProtectedRoute>
            }
          ></Route>
            <Route
            path="/appointments"
            element={
              <ProtectedRoute>
                <Appointments />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/ChangePassword"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/configuration"
            element={<ProtectedRoute>
              <Configuration />
            </ProtectedRoute>}
          > </Route>
          <Route
            path="/knowledge-base"
            element={<ProtectedRoute>
              <KnowledgeBase />
            </ProtectedRoute>}
          > </Route>
          <Route
            path="/agent"
            element={<ProtectedRoute>
              <Agent />
            </ProtectedRoute>}
          > </Route>
          <Route
            path="/phone-numbers"
            element={<ProtectedRoute>
              <PhoneNumbers />
            </ProtectedRoute>}
          > </Route>
          <Route
            path="/Admin"
            element={<ProtectedRoute>
              <Profile />
            </ProtectedRoute>}
          > </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {location.pathname !== "/" ? <Footer /> : ""}

      </>
    </MyContext.Provider>
  );
}

export default App;
