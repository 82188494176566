import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Form, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { InlineLoader } from "../../components/loader";
import { useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../../components/loader";
import 'react-toastify/dist/ReactToastify.css';
import { aiService } from "../../service/api.service";
import { MyContext } from "../../config/config";
import { useContext } from "react";
export const Home = () => {
  const {filterType, setFilterType, openLoad, setOpenLoad}=useContext(MyContext)
  const [loader, setLoader] = useState(false);
  const [dashbordData, setDashbordData] = useState([]);
  useEffect(() => {
    dashboardList()
  }, [])

  async function dashboardList() {
    setLoader(true)
    try {
      const response = await aiService.dashboard()
      if (response?.status == 200) {
        setDashbordData(response?.data)
        console.log("?????????????dashbord", response?.data)
        setLoader(false)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)
        
        swal({icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)
       
        swal({icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }


  return (
    <>
      <div className="dashboard-main-area">
        <div className="dashboard-area">
          <Row className="justify-content-center">
            <Col md={12}>
              <Row className="justify-content-center">
              {loader == true ? <ThreeDotSpinner /> : ""}
                {/* <Col md={5} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/total-availability.svg").default}
                      />
                    </div>
                    <Link to="/UserList" className="dashboardlink" onClick={()=>setFilterType("retailer")}>
                      <div className="card-content">
                        <h3>Total availability</h3>
                        <h2>
                          {" "}
                          {loader ? 
                          <InlineLoader/>
                          :
                          <>
                            {dashbordData ? <>{dashbordData?.retailer}</>:<>-</>}
                          </>
                        }12
                        </h2>

                      </div>
                    </Link>
                  </div>
                </Col> */}
                <Col md={5} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/total-app.svg").default}
                      />
                    </div>
                    <Link to="/appointments" className="dashboardlink" onClick={()=>setFilterType("wholesaler")}>
                      <div className="card-content">
                        <h3>Total Schedule Appointment</h3>
                        <h2>
                          {" "}
                          {loader ? 
                          <InlineLoader/>
                          :
                          <>
                            {dashbordData ? <>{dashbordData?.Appointments_count}</>:<>-</>}
                          </>
                        }
                        </h2>

                      </div>
                    </Link>
                  </div>
                </Col>
                {/* <Col md={5} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/total-user.svg").default}
                      />
                    </div>
                    <Link to="/UserList" className="dashboardlink" onClick={()=>setFilterType("trucker")}>
                      <div className="card-content">
                        <h3>Total Visit Users</h3>
                        <h2>
                          {" "}
                          {loader ? 
                          <InlineLoader/>
                          :
                          <>
                          {dashbordData ? <>{dashbordData?.trucker}</>:<>-</>}
                          </>
                        }32
                        </h2>

                      </div>
                    </Link>
                  </div>
                </Col> */}
                <Col md={5} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/total-lead.svg").default}
                      />
                    </div>
                    <Link to="/leads" className="dashboardlink">
                      <div className="card-content">
                        <h3>Total Leads</h3>
                        <h2>
                          {" "}
                          {loader ? 
                          <InlineLoader/>
                          :
                          <>
                           {dashbordData ? <>{dashbordData?.contactus_count}</>:<></>}
                          </>
                        }
                        </h2>

                      </div>
                    </Link>
                  </div>
                </Col>
                {/* <Col md={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/report1.svg").default}
                      />
                    </div>
                    <Link to="/OrderList" className="dashboardlink">
                      <div className="card-content">
                        <h3>Total Orders</h3>
                        <h2>
                          {" "}
                          {loader ? 
                          <InlineLoader/>
                          :
                          <>
                           {dashbordData ? <>{dashbordData?.order}</>:<>-</>}
                           
                          </>
                        }
                        </h2>

                      </div>
                    </Link>
                  </div>
                </Col> */}
                {/* <Col md={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/report1.svg").default}
                      />
                    </div>
                    <Link to="/LoadManagement/delivered" className="dashboardlink" onClick={()=>setOpenLoad(true)}>
                      <div className="card-content">
                        <h3>Total Loads Completed</h3>
                        <h2>
                          {" "}
                          {loader ? 
                          <InlineLoader/>
                          :
                          <>
                           {dashbordData ? <>{dashbordData?.load}</>:<>-</>}
                            
                          </>
                        }
                        </h2>

                      </div>
                    </Link>
                  </div>
                </Col> */}
              </Row>
             
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
