import { Col, Container, Row, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import { ThreeDotSpinner } from "../loader"
import { useEffect, useState } from "react";
import { Formik, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { aiService } from "../../service/api.service";
import swal from "sweetalert";

function Configuration() {
    const [loader, setLoader] = useState(false);
    const [settings, setSettings] = useState({})
    const super_u = localStorage.getItem('super_safe')

    useEffect(() => {
        getConfig()
    }, [])

    const configValidation = Yup.object().shape({
        openAiApi: Yup.string()
            .required('Open AI API Key is required'),
        deepgramKey: Yup.string()
            .required('Deepgram Key is required')
    });


    async function getConfig() {
        try {
            setLoader(true)
            let sets = await aiService.configuration('GET')
            sets = sets.data.data
            setSettings(sets)
            for (const [key, value] of Object.entries(sets)) {
            }
            setLoader(false)
        } catch (err) {
            setLoader(false)
        }
    }


    return (
        <div className="dashboard-main-area">
            {loader ? <ThreeDotSpinner /> : ""}
            <Container fluid>
                <div className="dashboard-area-heading management-heading">
                    <Row className="row mb-3">
                        <div className="col-8 ms-4">
                            <h2 className="align-self-center m-0">Configuration</h2>
                        </div>
                    </Row>
                    <Row className="pb-4 justify-content-center">
                        <Col md={11}>
                            <div className="availabilit-section input-w-100">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        openAiApi: settings?.openAiApi,
                                        deepgramKey: settings?.deepgramKey,
                                    }}
                                    validationSchema={configValidation}
                                    onSubmit={async (values, { setSubmitting }) => {
                                        setSubmitting(false);
                                        setLoader(true)
                                        try {
                                            let srv = await aiService.configuration('post', values)
                                            swal({ icon: "success", text: "Updated successfully." })
                                        } catch (error) {
                                            swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
                                        }
                                        setLoader(false)
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue
                                    }) => {
                                        return (
                                            <Form onSubmit={handleSubmit}>
                                                {
                                                    super_u != 'true' &&
                                                    <Row>
                                                        <Col md="4">
                                                            <Form.Group controlId="formOpenAiApi" className="mt-3">
                                                                <Form.Label>OPEN AI API</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    className="w-100"
                                                                    name="openAiApi"
                                                                    placeholder="Enter Open AI API Key"
                                                                    value={values.openAiApi}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={touched.openAiApi && !!errors.openAiApi}
                                                                />
                                                                <ErrorMessage name="openAiApi" component="div" className="text-danger" />
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md="4">
                                                            <Form.Group controlId="formDeepgramKey" className="mt-3">
                                                                <Form.Label>DEEPGRAM KEY</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    className="w-100"
                                                                    name="deepgramKey"
                                                                    placeholder="Enter Deepgram Key"
                                                                    value={values.deepgramKey}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    isInvalid={touched.deepgramKey && !!errors.deepgramKey}
                                                                />
                                                                <ErrorMessage name="deepgramKey" component="div" className="text-danger" />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                }

                                                <Button variant="success" type="submit" className="mt-4" disabled={isSubmitting}>
                                                    Submit
                                                </Button>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default Configuration