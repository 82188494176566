import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Table,
  Button,
  Modal,
  Form

} from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ThreeDotSpinner } from "../loader";
import { useNavigate, Link, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import copy from "copy-to-clipboard";
import { aiService } from "../../service/api.service";
import { apiBaseUrl } from "../../config/config";
import userImg from "../../assets/images/byDefaultUser.png"
import { MyContext, apiUrl } from "../../config/config";
import { useContext } from "react";
import moment from "moment";
import 'bootstrap/dist/css/bootstrap.min.css';

export const Appointments = () => {
  const { filterType, setFilterType } = useContext(MyContext)
  const [userData, setUserData] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1)
  const [page_size, setpage_size] = useState(4)

  const [deactivatedReason, setDeactivatedReason] = useState("")
  const [reasonError, setReasonError] = useState("")
  const [reasonData, setReasonData] = useState("")
  const [userId, setUserId] = useState("")
  const [updateData, setupdateData] = useState()



  const [show, setShow] = useState(false);

  const handleClose = () => { setShow(false); setReasonError("") };
  const handleShow = () => setShow(true);

  const [viewReason, setViewReason] = useState(false);
  const handelCloseViewReasion = () => setViewReason(false);

  const renderTooltip_view = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Key Note
    </Tooltip>
  );

  const renderTooltip_reschedule = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Reschedule
    </Tooltip>
  );

  const renderTooltip_confirm = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Confirm
    </Tooltip>
  );

  const handeshowViewReasion = (data) => {
    setupdateData(data)
    setViewReason(true);
  }
  useEffect(() => {

    appointments(page, search)

  }, [])

  async function appointments(page, search) {
    setLoader(true)
    try {

      const response = await aiService.appointments(page, page_size, search)
      if (response?.status == 200) {
        let responseData = response.data.data
        setUserData(responseData)
        setTotalItems(response?.data?.records)
        console.log("?????????????list", responseData)
        setLoader(false)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)

        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)

        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }

  function handlePageChange(pageNumber) {
    console.log("??????page", pageNumber)
    setPage(pageNumber);
    appointments(pageNumber, search)
  }

  function handleUpdateSearch(search) {
    var date1 = new Date(search);
    var dateTime1 = moment(date1).format("DD/MM/YYYY");
    console.log(dateTime1)
    setSearch(search);
    setPage(1);
    appointments(page, dateTime1)
  }

  function onChangeReason(e) {
    const reason = e.trim()
    setDeactivatedReason(reason)
    console.log("??????????????", reason)
    if (!reason) {
      setReasonError("Please enter the reason")
    } else {
      setReasonError("")
    }
  }
  const updatetime = (val, e) => {
    let params = {
      _id: updateData._id,
      date: updateData.date,
      time: val
    }
    setupdateData(params)
  }

  async function updateAppointment(data, update) {
    setLoader(true)
    try {
      console.log("====", data);
      let params = {
        id: data._id,
        date: data.date,
        time: data.time
      }
      if (update) {
        params.update = true
      }

      const response = await aiService.updateappointments(params)
      if (response.status == 200) {
        setLoader(false)
        if (update) {
          handelCloseViewReasion()
          swal("Success", "Appointment Rescheduled", "success").then(() => {

            setShow(false)
            setDeactivatedReason("")
            setReasonError("")
            setUserId("")
          })

        } else {
          swal("Success", "Appointmnet confirmed successfully.", "success").then(() => {

            setShow(false)
            setDeactivatedReason("")
            setReasonError("")
            setUserId("")
          })
        }
        appointments(page, search)
        //  swal("Success", response?.data?.message, "success").then(() => {


      }
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        })
      } else {
      }
    }
  }
  function confirm(data) {
    swal({
      title: "Are you sure?",
      text: "You want to confirm this appointment.",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        let params = {
          _id: data._id,
          date: "",
          time: ""
        }
        updateAppointment(params, false)


      }
    });

  }

  function view(data) {
    setReasonData(data)
    console.log(">>>>>", data)
    setShow(true)
  }

  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}

        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">Appointments</h2>
                  </div>
                  <div className="heading-top-area-right">

                    <div className="search-area">
                      <label>Date</label>
                      <form
                        autoComplete="off"
                        onSubmit={(event) => {
                          event.preventDefault();
                        }}
                      >
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => handleUpdateSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (!e.target.value && e.code === "Space") {
                              e.preventDefault();
                            }
                          }}
                        />
                        {/* <i
                          className="fa fa-search"
                          aria-hidden="true"
                          onClick={() => appointments(page)}
                        ></i> */}
                        <i className="fa fa-close d-none" aria-hidden="true"></i>
                      </form>
                    </div>
                    {/* <div className="export-csv-btn">
                    <Button type="button" variant="unset" onClick={download_csv}>Export CSV</Button>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={11}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        {/* <th>Image</th> */}
                        <th>Name</th>
                        {/* <th>Last Name</th> */}
                        {/* <th className="email-section">Email</th> */}
                        <th>Phone Number</th>
                        <th>Appointment Date</th>
                        <th>Appointment Time</th>
                        <th>Key Note</th>
                        <th className="action-btn-box">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData && userData?.length > 0 ? (
                        userData.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{(page - 1) * 10 + (index + 1)}</td>
                              {/* <td>{data?.image ? <img src={apiBaseUrl + data?.image} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />} </td> */}
                              <td>{data?.name ? <>{data?.name}{" "}</> : <>-</>}</td>
                              <td>{data?.number ? <>{data?.number}</> : <>-</>}</td>
                              <td>{data?.date ? <>{data.date}</> : <>-</>}</td>
                              <td>{data?.time ? <>{moment(data.time, ["HH:mm"]).format("h:mm A")}</> : <>-</>}</td>
                              <td>
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip_view}
                                >
                                  <button className="btn btn-success" onClick={() => view(data.summary)}>
                                    <img
                                      src={
                                        require("../../assets/images/eye-icon (1).svg")
                                          .default
                                      }
                                      className="action-icons"
                                      alt="da"
                                    />
                                  </button>
                                </OverlayTrigger>
                              </td>
                              <td className="action-btn-box">
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip_reschedule}
                                >
                                  <button onClick={() => handeshowViewReasion(data)} className="btn btn-success reschedule-btn">
                                    <img
                                      src={
                                        require("../../assets/images/reschedule-icon.svg")
                                          .default
                                      }
                                      alt="da"
                                      className="action-icons"
                                    />

                                  </button>
                                </OverlayTrigger>
                                {!data.confirmed ?
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip_confirm}
                                  >
                                    <button onClick={() => confirm(data)} className="btn btn-success confirm-btn ml-2">

                                      <img
                                        src={
                                          require("../../assets/images/confirm-icon.svg")
                                            .default
                                        }
                                        alt="da"
                                        className="action-icons"
                                      />

                                    </button>
                                  </OverlayTrigger>
                                  : null}
                              </td>
                            </tr>
                          )
                        })) : (
                        <tr className="no-dat-found-box">
                          <td colSpan={10}>
                            <img
                              src={
                                require("../../assets/images/no-data.svg")
                                  .default
                              }
                              alt="da"
                            />
                            <h3 className="text-center text-light">
                              No data found
                            </h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {/* {console.log("?>>>>>>>>>>>>", totalItems)} */}
                  {userData.length > 0 && totalItems > page_size ?

                    <Pagination
                      activePage={page}
                      itemsCountPerPage={page_size}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                    : null
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Modal show={show} onHide={handleClose} backdrop="static">
          <Modal.Header>
            <Modal.Title>Call Key Points</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p dangerouslySetInnerHTML={{ __html: reasonData }} className="call-key-text"></p>

          </Modal.Body>
          <Modal.Footer className="pt-3">
            <Button variant="secondary" onClick={handleClose} className="close-btn">
              Close
            </Button>

          </Modal.Footer>
        </Modal>



        <Modal show={viewReason} onHide={handelCloseViewReasion} backdrop="static"

          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Reschedule Appointment
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-3">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Date"
                  disabled
                  // onChange={(e) => onChangeOldPassword(e.target.value)}
                  value={updateData ? updateData.date : ""}
                />
                {/* <span className="text-danger">{oldpassworderror}</span> */}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Time</Form.Label>
                <Form.Control
                  type="time"
                  dateFormat="hh:mm A"
                  step="00:15"
                  placeholder="Enter Time"
                  value={updateData ? moment(updateData.time, ["h:mm A"]).format("HH:mm") : ""}
                  onChange={(e) => { updatetime(e.target.value, e) }}
                  autofocus={true}

                />
                {/* <span className="text-danger">{oldpassworderror}</span> */}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handelCloseViewReasion} className="close-btn btn-secondary">Close</Button>
            <Button onClick={() => updateAppointment(updateData, true)}>Reschedule</Button>
          </Modal.Footer>
        </Modal>

      </div>
    </>
  );
};
