import { Button, Card, Form } from "react-bootstrap"
import swal from "sweetalert";
import { aiService } from "../../service/api.service";
import { useEffect, useState } from "react";

function Transcriber({ selectedPhone, setLoader, getConfig }) {
  useEffect(() => {
    if (selectedPhone?.transcription) {
      setSelectedValue(selectedPhone.transcription)
    }
  }, [selectedPhone])
  const [selectedValue, setSelectedValue] = useState("");
  const options = [
    { name: "Bulgarian", value: "bg" },
    { name: "Catalan", value: "ca" },
    { name: "Chinese (Mandarin, Simplified)", value: "zh" },
    { name: "Czech", value: "cs" },
    { name: "Danish", value: "da" },
    { name: "Dutch", value: "nl" },
    { name: "English", value: "en" },
    { name: "Estonian", value: "et" },
    { name: "Finnish", value: "fi" },
    { name: "Flemish", value: "nl-be" },
    { name: "French", value: "fr" },
    { name: "German", value: "de" },
    { name: "Greek", value: "el" },
    { name: "Hindi", value: "hi" },
    { name: "Hungarian", value: "hu" },
    { name: "Indonesian", value: "id" },
    { name: "Italian", value: "it" },
    { name: "Japanese", value: "ja" },
    { name: "Korean", value: "ko" },
    { name: "Latvian", value: "lv" },
    { name: "Lithuanian", value: "lt" },
    { name: "Malay", value: "ms" },
    { name: "Norwegian", value: "no" },
    { name: "Polish", value: "pl" },
    { name: "Portuguese", value: "pt" },
    { name: "Romanian", value: "ro" },
    { name: "Russian", value: "ru" },
    { name: "Slovak", value: "sk" },
    { name: "Spanish", value: "es" },
    { name: "Swedish", value: "sv" },
    { name: "Thai", value: "th" },
    { name: "Turkish", value: "tr" },
    { name: "Ukrainian", value: "uk" },
    { name: "Vietnamese", value: "vi" }
  ];

  async function submitPhoneSetting() {
    if (!selectedValue) {
      swal({ icon: "warning", text: "Please select a language for the call transcription." })
      return;
    }
    try {
      setLoader(true)
      await aiService.updatePhoneNumber({ "id": selectedPhone._id, transcription: selectedValue })
      setLoader(false)
      swal({ icon: "success", text: "Transcription language for phone number has been updated." })
      await getConfig()
    } catch (err) {
      setLoader(false)
    }
  }

  return <>
    <Card>
      <Card.Header>
        <Card.Title>
          <h5>Transcription</h5>
          <h6>This section allows you to configure the transcription settings for the assistant.</h6>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <select className="form-control" onChange={(e) => setSelectedValue(e.target.value)}>
          <option value="">Select a language</option>
          {
            options.map(op => (
              <option value={op.value} selected={selectedValue == op.value}>{op.name}</option>
            ))
          }
        </select>
        <div>
          <Button className="mt-3" variant="success" onClick={() => submitPhoneSetting()}>Submit</Button>
        </div>
      </Card.Body>
    </Card>
  </>
}

export default Transcriber