import { createContext } from "react";
import swal from "sweetalert";

 export const apiUrl = "https://api.aioffice.pro"
 export const apiBaseUrl = "https://api.aioffice.pro"
// export const apiUrl = "http://127.0.0.1:8000"
// export const apiBaseUrl = "https://api.distrix.ai"
export const DATE_OPTIONS = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
}
export const MyContext = createContext();

export const length_unit = "Ft"

export const Unauthorized=()=>{swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
    localStorage.clear()
    window.location.href = "/";
    console.log('OK button clicked after error alert');
})}
