import { Button, Card, Form } from "react-bootstrap"
import swal from "sweetalert";
import { aiService } from "../../service/api.service";
import { useEffect, useState } from "react";

function Analysis({ selectedPhone, setLoader, getConfig }) {
  const [selectedValue, setSelectedValue] = useState("");
  useEffect(() => {
    if (selectedPhone?.analysis) {
      setSelectedValue(selectedPhone.analysis)
    }
  }, [selectedPhone])

  async function submitPhoneSetting() {
    if (!selectedValue) {
      swal({ icon: "warning", text: "Please enter prompt for the phone call analysis." })
      return;
    }
    try {
      setLoader(true)
      await aiService.updatePhoneNumber({ "id": selectedPhone._id, "analysis": selectedValue })
      setLoader(false)
      swal({ icon: "success", text: "Analysis prompt for phone number has been updated." })
      await getConfig()
    } catch (err) {
      setLoader(false)
    }
  }

  return <>
    <Card>
      <Card.Header>
        <Card.Title>
          <h5>Summary</h5>
          <h6>This is the prompt that's used to summarize the call. You can find the summary in the Call List Page.</h6>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Form.Control
          as={"textarea"}
          className="w-100 textarea"
          rows={6}
          placeholder="You are an expert call evaluator. You will be given a transcript of a call and the system prompt of the AI participant. Determine if the call was successful based on the objectives inferred from the system prompt."
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
        />
        <div>
          <Button className="mt-3" variant="success" onClick={() => submitPhoneSetting()}>Submit</Button>
        </div>
      </Card.Body>
    </Card>
  </>
}

export default Analysis