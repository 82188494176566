import { Col, Container, Row, Button, Form, OverlayTrigger, Tooltip, ListGroup, Modal, Tab, Nav, Table } from "react-bootstrap"
import { ThreeDotSpinner } from "../loader"
import { useEffect, useRef, useState } from "react";
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { aiService } from "../../service/api.service";
import swal from "sweetalert";
import samples from "./samples.js"
import Swal from "sweetalert2"
import { apiUrl } from "../../config/config.js";

function Agent() {
  const [loader, setLoader] = useState(false);
  const [agents, setAgents] = useState([])
  const [activeTab, setActiveTab] = useState('blank');
  const [addAgentModal, setAddAgentModal] = useState(false)
  const [agentName, setAgentName] = useState("")
  const [templateData, setTemplateData] = useState("")
  const [selectAgent, setSelectAgent] = useState({})
  const [file, setFile] = useState("")
  const [delAgent, setDelAgent] = useState(0)
  const inputFileRef = useRef();

  const configValidation = Yup.object().shape({
    welcomeMsg: Yup.string().trim()
      .required('Welcome message is required').test('len', 'Welcome Message cannot exceed 250 characters.', val => val.length < 250),
    systemPrompt: Yup.string().trim().test('len', 'System prompt cannot exceed 3500 characters.', val => val.length < 3500)
      .required('System prompt is required'),
  });

  useEffect(() => {
    getAgents()
  }, [delAgent])

  async function getAgents() {
    setLoader(true)
    let tempAgents = await aiService.getAgents()
    setAgents(tempAgents.data.data)
    setLoader(false)
    if (tempAgents.data.data.length) {
      setSelectAgent(tempAgents.data.data[0])
    }
  }

  function handleTemplate(name) {
    setActiveTab(name)
    if (name != 'blank') {
      let sampleAgent = samples.find(a => a.type == name)
      setAgentName(sampleAgent.name)
      setTemplateData(sampleAgent)
    }
  }

  async function createAgent() {
    if (!agentName.trim()) {
      swal({ icon: 'warning', text: 'Please enter agent name.' })
      return;
    }
    try {
      setLoader(true)
      let create = await aiService.createAgent({ name: agentName, create: true, ...templateData })
      setLoader(false)
      swal({ icon: 'success', text: 'Agent created successfully.' })
      setAgentName('')
      setAddAgentModal(false)
      await getAgents()
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        });
      } else {
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
      }
    }
  }

  async function deleteAgent(agent_id) {
    if (!agent_id) {
      swal({ icon: 'error', text: "No agent selected", button: "OK" })
      return;
    }
    Swal.fire({
      text: "Are you sure you want to delete the agent?",
      showCancelButton: true
    }).then(async r => {
      if (r.isConfirmed) {
        try {
          setLoader(true)
          await aiService.deleteAgent(agent_id)
          setLoader(false)
          swal({ icon: 'success', text: 'Agent deleted successfully.' })
          setDelAgent(delAgent + 1)
        } catch (error) {
          setLoader(false)
          if (error?.response?.status == 401) {
            swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
              localStorage.clear()
              window.location.href = "/";
            });
          } else {
            swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
          }
        }
      }
    })

  }

  function onFileChangeCapture(e) {
    setFile(e.target.files[0])
  }

  async function addKnowledge(agent_id) {
    if (file) {
      setLoader(true)
      try {
        let form = new FormData()
        form.append('files', file)
        form.append('agent_id', agent_id)
        const add = await aiService.addKnowledgeBase(form)
        swal({ icon: "success", text: "File uploaded." })
        await getAgents()
        setLoader(false)
        setFile(null)
      } catch (e) {
        setLoader(false)
        swal({ icon: 'error', text: e?.response?.data?.message ? e?.response.data.message : e?.message, button: "OK" })
      }
    } else {
      setLoader(false)
      swal({ icon: 'error', text: 'Please upload a file.' })
      return;
    }
  }

  async function clearKnowledge() {
    Swal.fire({
      text: "Are you sure you want to clear the knowledge base of agent?",
      showCancelButton: true
    }).then(async (r) => {
      if (r.isConfirmed) {
        setLoader(true)
        try {
          const remove = await aiService.clearKnowledgeBase({ "agent_id": selectAgent._id })
          swal({ icon: "success", text: "Knowledge base cleared." })
          setSelectAgent({ ...selectAgent, knowledge: [] })
          setLoader(false)
        } catch (e) {
          setLoader(false)
          swal({ icon: 'error', text: e?.response?.data?.message ? e?.response.data.message : e?.message, button: "OK" })
          return;
        }
      }
    })
  }


  return (
    <div className="dashboard-main-area">
      {loader ? <ThreeDotSpinner /> : ""}
      <Container fluid>
        <div className="dashboard-area-heading management-heading">
          <Row className="row mb-3">
            <div className="col-8 ms-4">
              <h2 className="align-self-center m-0">Agents</h2>
            </div>
          </Row>
          <Row className="pb-4 ms-4">
            <Col md={3}>
              <div className="availabilit-section">

                <Button className="btn-success w-100" onClick={() => setAddAgentModal(true)}>
                  <span style={{ fontWeight: "bold", fontSize: "15px" }}>Create Agent</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="ms-1" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                  </svg>
                </Button>
                {
                  agents.length
                    ?
                    <div className="mt-3">
                      <ListGroup activeKey={`#${selectAgent._id}`}>
                        {
                          agents.map((a, i) => (
                            <ListGroup.Item action className="mb-2 agent-list-name d-flex justify-content-between align-items-center" href={"#" + a._id} onClick={() => {
                              setSelectAgent(a)
                              setFile(null)
                            }}>
                              {a.name}
                              <Button size="sm" variant="danger" onClick={() => deleteAgent(a._id)}>
                                <svg className="text-danger" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                </svg>
                              </Button>
                            </ListGroup.Item>
                          ))
                        }
                      </ListGroup>
                    </div>
                    : ""
                }
              </div>
            </Col>
            <Col md={9}>
              <Row>
                <Col md={12}>
                  <div className="availabilit-section input-w-100">
                    {
                      selectAgent?.name ?
                        <>
                          <h3>Agent Settings</h3>
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              name: selectAgent?.name ? selectAgent.name : '',
                              welcomeMsg: selectAgent?.welcomeMsg ? selectAgent.welcomeMsg : '',
                              systemPrompt: selectAgent?.systemPrompt ? selectAgent.systemPrompt : ''
                            }}
                            validationSchema={configValidation}
                            onSubmit={async (values, { setSubmitting }) => {
                              setSubmitting(false);
                              try {
                                setLoader(true)
                                await aiService.createAgent({ ...values, agent_id: selectAgent._id })
                                setLoader(false)
                                swal({ icon: "success", text: "Updated successfully." })
                                await getAgents()
                              } catch (err) {
                                setLoader(false)
                              }

                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                            }) => {
                              return (
                                <Form onSubmit={handleSubmit}>

                                  <Row>
                                    <Col md="12">
                                      <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                          className="w-100"
                                          name="name"
                                          placeholder="The name of the agent."
                                          value={values.name}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          isInvalid={touched.name && !!errors.name}
                                        />
                                        <ErrorMessage name="name" component="div" className="text-danger" />
                                      </Form.Group>
                                      <Form.Group className="mt-3">
                                        <Form.Label>WELCOME MESSAGE
                                          <OverlayTrigger
                                            // placement="right"
                                            delay={{ show: 100, hide: 400 }}
                                            overlay={<Tooltip>
                                              The first message that the assistant will say.
                                            </Tooltip>}
                                          >
                                            <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                                            </svg>
                                          </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control
                                          className="w-100"
                                          name="welcomeMsg"
                                          placeholder="The first message that the assistant will say."
                                          value={values.welcomeMsg}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          isInvalid={touched.welcomeMsg && !!errors.welcomeMsg}
                                        />
                                        {/* <Field type="text" name="welcomeMsg" id="welcomeMsg" className="form-control" /> */}
                                        <ErrorMessage name="welcomeMsg" component="div" className="text-danger" />
                                      </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                      <Form.Group className="mt-3">
                                        <Form.Label>SYSTEM PROMPT
                                          <OverlayTrigger
                                            delay={{ show: 100, hide: 400 }}
                                            overlay={<Tooltip>
                                              The system prompt can be used to configure the context, role, personality, instructions and so on for the assistant.
                                            </Tooltip>}
                                          >
                                            <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                                            </svg>
                                          </OverlayTrigger>

                                        </Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={10}
                                          className="w-100 textarea"
                                          name="systemPrompt"
                                          placeholder="The system prompt can be used to configure the context, role, personality, instructions and so on for the assistant."
                                          value={values.systemPrompt}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          isInvalid={touched.systemPrompt && !!errors.systemPrompt}
                                        />
                                      </Form.Group>
                                      <ErrorMessage name="systemPrompt" component="div" className="text-danger" />

                                    </Col>
                                  </Row>

                                  <Button type="submit" className="mt-4 btn-success px-4" disabled={isSubmitting}>
                                    Submit
                                  </Button>
                                </Form>
                              )
                            }}
                          </Formik>
                          <Col md={12} className="mt-4">
                            <div className="availabilit-section knowledge-base-section">
                              <div className="d-flex justify-content-between">
                                <h3>Knowledge Base</h3>
                                {
                                  selectAgent.knowledge.length ?
                                    <Button variant="danger" size="sm" className="align-items-center d-flex delete-all-btns" onClick={() => clearKnowledge()}>
                                      <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                      </svg>
                                      Delete all
                                    </Button>
                                    : ""
                                }
                              </div>
                              <input
                                className="d-none"
                                type="file"
                                ref={inputFileRef}
                                accept=".pdf,.doc,.docx,.txt"
                                onChangeCapture={onFileChangeCapture}
                              />
                              <Button className="btn-success" onClick={() => {
                                inputFileRef.current.click();
                              }}>
                                Add File
                              </Button>

                              {
                                file ?
                                  <div className="pdf-view">
                                    <h5>{file.name}</h5>
                                    <Button onClick={() => addKnowledge(selectAgent._id)}>
                                      <i className="fa fa-upload me-2" aria-hidden="true"></i>
                                      Upload
                                    </Button>
                                  </div>
                                  : ""
                              }
                              <div className="table-outer-box">
                                {
                                  selectAgent.knowledge.length ?
                                    <Table responsive="sm" className="UserListTable">
                                      <thead>
                                        <tr>
                                          <th>File Name</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          selectAgent.knowledge?.map(r => (
                                            <tr>
                                              <td>
                                                <a href={`${apiUrl}/media/${r.url}`} target="_blank" className="text-dark">{r.filename}</a>
                                              </td>
                                              <td>
                                                <span className="status-task">
                                                {r.status}
                                                </span></td>
                                            </tr>
                                          ))
                                        }
                                      </tbody>
                                    </Table>
                                    :
                                    <h6 className="border text-center py-4">No files added</h6>
                                }
                              </div>
                            </div>
                          </Col>
                        </>
                        :
                        <h5 className="text-center">Kindly create/select an Agent.</h5>
                    }
                  </div>
                </Col>


              </Row>
            </Col>
          </Row>
        </div>
      </Container>

      <Modal show={addAgentModal} onHide={() => setAddAgentModal(false)} className="create-agent-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            Create New Agent
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formAssistantName">
              <Form.Label>Assistant Name (This can be adjusted at any time after creation.)</Form.Label>
              <Form.Control type="text" placeholder="New Assistant" onChange={(e) => setAgentName(e.target.value)} value={agentName} />
            </Form.Group>
            <Row>
              <Col md={12} onClick={() => handleTemplate('blank')}>
                <div className={`quickstart-card blank-template-btn ${activeTab == 'blank' ? 'active' : ''}`}>
                  <h6>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-2" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                    </svg>
                    Blank Template
                  </h6>
                  <p>This blank slate template with minimal configurations. It's a starting point for creating your custom assistant.</p>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12}>
                <div className="template-description">
                  <h5>Quickstart</h5>
                  <div className="quickstart-templates">
                    <Row>
                      <Col md={6} onClick={() => handleTemplate('appointment')}>
                        <div className={`quickstart-card ${activeTab == 'appointment' ? 'active' : ''}`}>
                          <h6>Appointment Setter</h6>
                          <p>Designed for dental practices to demonstrate setting appointments.</p>
                        </div>
                      </Col>
                      <Col md={6} onClick={() => handleTemplate('customer')}>
                        <div className={`quickstart-card ${activeTab == 'customer' ? 'active' : ''}`}>
                          <h6>Customer Support</h6>
                          <p>A versatile template designed with emotional intelligence and technical knowledge.</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} onClick={() => handleTemplate('qa')}>
                        <div className={`quickstart-card ${activeTab == 'qa' ? 'active' : ''}`}>
                          <h6>Inbound Q/A</h6>
                          <p>An inbound call agent example designed for comprehensive support.</p>
                        </div>
                      </Col>
                      <Col md={6} onClick={() => handleTemplate('game')}>
                        <div className={`quickstart-card ${activeTab == 'game' ? 'active' : ''}`}>
                          <h6>Game NPC</h6>
                          <p>An assistant for demonstrating an in-game NPC.</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="close-btn" onClick={() => setAddAgentModal(false)}>
            Close
          </Button>
          <Button className="btn-success agent-btn-success" onClick={() => createAgent()}>Create Agent</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Agent