import { useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap"
import swal from "sweetalert";
import { aiService } from "../../service/api.service";

function PhoneVoice({ selectedPhone, setLoader, getConfig }) {
  useEffect(() => {
    if (selectedPhone?.voice) {
      setSelectedValue(selectedPhone.voice)
    }
  }, [selectedPhone])
  const [selectedValue, setSelectedValue] = useState("");
  const data = [
    { name: "Asteria", accent: "English (US)", gender: "Female", voice: "asteria_docs_venw0r.wav", value: "aura-asteria-en" },
    { name: "Luna", accent: "English (US)", gender: "Female", voice: "luna_docs_clom0e.wav", value: "aura-luna-en" },
    { name: "Stella", accent: "English (US)", gender: "Female", voice: "stella_docs_xh5jbv.wav", value: "aura-stella-en" },
    { name: "Athena", accent: "English (UK)", gender: "Female", voice: "athena_docs_wyznud.wav", value: "aura-athena-en" },
    { name: "Hera", accent: "English (US)", gender: "Female", voice: "hera_docs_xjkt4x.wav", value: "aura-hera-en" },
    { name: "Orion", accent: "English (US)", gender: "Male", voice: "orion_docs_aljv1q.mp3", value: "aura-orion-en" },
    { name: "Arcas", accent: "English (US)", gender: "Male", voice: "arcas_docs_pc9hxp.mp3", value: "aura-arcas-en" },
    { name: "Perseus", accent: "English (US)", gender: "Male", voice: "perseus_docs_ap7fc0.wav", value: "aura-perseus-en" },
    { name: "Angus", accent: "English (Ireland)", gender: "Male", voice: "angus_docs_lgse2b.wav", value: "aura-angus-en" },
    { name: "Orpheus", accent: "English (US)", gender: "Male", voice: "orpheus_docs_zdlpcf.wav", value: "aura-orpheus-en" },
    { name: "Helios", accent: "English (UK)", gender: "Male", voice: "helios_docs_ycjwoo.wav", value: "aura-helios-en" },
    { name: "Zeus", accent: "English (US)", gender: "Male", voice: "zeus_docs_fupdiv.wav", value: "aura-zeus-en" },
  ];

  const handleSelection = (val) => {
    setSelectedValue(val);
  };

  async function submitPhoneSetting() {
    if (!selectedValue) {
      swal({ icon: "warning", text: "Please select a voice for the phone number." })
      return;
    }
    try {
      setLoader(true)
      await aiService.updatePhoneNumber({ "id": selectedPhone._id, "voice": selectedValue })
      setLoader(false)
      swal({ icon: "success", text: "Voice for phone number has been updated." })
      await getConfig()
    } catch (err) {
      setLoader(false)
    }
  }
  return <>
    <Card>
      <Card.Header>
        <Card.Title>
          <h5>Voice Configuration</h5>
          <h6>Choose from the list of voices.</h6>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Table >
          <thead>
            <tr>
              <th>Name</th>
              <th>Accent</th>
              <th>Gender</th>
              <th>Listen</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className="d-flex">
                    <Form.Check
                      type="radio"
                      name="voice-selection"
                      checked={selectedValue == item.value}
                      onChange={() => handleSelection(item.value)}
                    />
                    {item.name}
                  </div>
                </td>
                <td>{item.accent}</td>
                <td>{item.gender}</td>
                <td>
                  <audio style={{ height: "35px" }} id={item.voice} controls>
                    <source src={`/voices/${item.voice}`} type="audio/wav"></source>
                  </audio>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div>
          <Button variant="success" onClick={() => submitPhoneSetting()}>Submit</Button>
        </div>
      </Card.Body>
    </Card>
  </>
}

export default PhoneVoice