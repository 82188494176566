import React, { useContext, useEffect } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { MyContext } from "../config/config";
const Header = () => {
  const navigate = useNavigate();
  const {editProfilr, setEditProfile}=useContext(MyContext)
  const name = JSON.parse(localStorage.getItem("name"))
  
  return (
    <>
      <div className="admin-header">
        <Container fluid>
          <div className="header-right">
            <div className="logout-btn">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <span className="text-black me-2" style={{fontWeight:"bold", fontSize:"16px"}}>Hello, {name}</span>
                  <img src={require("../assets/images/user.png")} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                <Dropdown.Item
                onClick={()=>{setEditProfile(false);navigate("/Admin")}}
                >
                    <i className="fa fa-user" aria-hidden="true"></i>
                    My Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={()=>{setEditProfile(true);navigate("/Admin")}}
                  >
                    <i class="fa fa-pencil-square" aria-hidden="true"></i>
                    Edit Profile
                  </Dropdown.Item>
                <Dropdown.Item
                    onClick={()=>{navigate("/ChangePassword")}}
                  >
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item
                   onClick={()=>{
                    swal({
                      title: 'Are you sure?',
                      text: 'You want to proceed with the logout?',
                      icon: 'warning',
                      buttons: ['Cancel', 'Yes, logout'],
                      dangerMode: true,
                    }).then((confirmed) => {
                      if (confirmed) {

                        localStorage.clear();
                        navigate("/");
                        
                      }
                    })
                   }}
                    
                  >
                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                    Sign Out
                  </Dropdown.Item>
                  
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Header;
