import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { aiService } from "../../service/api.service";
import * as Yup from 'yup';
import { ErrorMessage, Field, Formik } from "formik";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import swal from "sweetalert";
import PhoneInput from 'react-phone-input-2';

function PhoneModel({ selectedPhone, agents, setSelectedAgent, setLoader, selectedAgent, getConfig }) {
  const outboundValidation = Yup.object().shape({
    phoneNumber: Yup.string().trim().required('Phone Number is required')
      .test('valid-phone', 'Phone number is not valid', function (value) {
        if (!value) return false;
        const phoneNumber = parsePhoneNumberFromString(value);
        return phoneNumber && phoneNumber.isValid();
      }),
    agent: Yup.string().trim().required('Please select an agent.')
  });

  async function submitPhoneSetting() {
    if (!selectedAgent) {
      swal({ icon: "warning", text: "Please select an agent for the phone number." })
      return;
    }
    try {
      setLoader(true)
      await aiService.updatePhoneNumber({ "id": selectedPhone._id, "agent_id": selectedAgent })
      setLoader(false)
      swal({ icon: "success", text: "Agent successfully associated with phone number." })
      await getConfig()
    } catch (err) {
      setLoader(false)
    }
  }

  return <>
    <Card>
      <Card.Header>
        <h5>{selectedPhone.phoneNumber}</h5>
        <h6>{selectedPhone.label}</h6>
      </Card.Header>
      <Card.Body>
        <h5>Settings</h5>
        <small>You can assign an assistant to the Phone number so that whenever someone calls this Phone Number the assistant will automatically be assigned to the call.</small>
        <div>
          <Form.Group className="mt-3">
            <label htmlFor="ib_phone">Phone Number</label>
            <Form.Control
              value={selectedPhone.phone}
              disabled
              id="ib_phone"
              className="w-100"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <label htmlFor="id_asis">Agent</label>
            <select className="form-control w-100" onChange={(e) => setSelectedAgent(e.target.value)}>
              <option value="">Select Agent</option>
              {
                agents.map(a => (
                  <option value={a._id} selected={selectedPhone.agent == a._id} >{a.name}</option>
                ))
              }
            </select>
          </Form.Group>
          <Form.Group className="mt-3">
            <Button className="btn-success px-4" onClick={() => submitPhoneSetting()}>Submit</Button>
          </Form.Group>
        </div>
      </Card.Body>
    </Card>

    <Card className="mt-3">
      <Card.Header>
        <Card.Title>
          <h5 className="pb-0">Outbound Call Form</h5>
        </Card.Title>
        <small>You can make an outbound call using the below form.</small>
      </Card.Header>
      <Card.Body>
        <Formik
          enableReinitialize={true}
          initialValues={{
            phone: '',
            phoneNumber: '',
            agent: ''
          }}
          validationSchema={outboundValidation}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(false);
            try {
              setLoader(true)
              await aiService.makeCall({ ...values, phone_id: selectedPhone._id })
              setLoader(false)
              swal({ icon: "success", text: "Call placed successfully." })
            } catch (err) {
              setLoader(false)
              console.log(err.response?.data?.message)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => {
            return (
              <Form onSubmit={handleSubmit}>

                <Row>
                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Outbound Phone Number
                      </Form.Label>
                      <Field name="phoneNumber">
                        {({ field }) => (
                          <PhoneInput
                            country={'us'}
                            inputClass="w-100"
                            value={values.phoneNumber}
                            onChange={(phone, c, e, value) => {
                              setFieldValue('phone', phone)
                              setFieldValue('phoneNumber', value)
                            }}
                            inputProps={{
                              name: 'phoneNumber',
                              required: true,
                              autoFocus: true,
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="phoneNumber" component="div" className="text-danger" />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="mt-3">
                      <Form.Label>Agent</Form.Label>
                      <Field name="agent">
                        {({ field }) => (
                          <select name="agent" onChange={handleChange} required className="form-control w-100">
                            <option value="">Select an agent</option>
                            {
                              agents.map(a => (
                                <option value={a._id}>{a.name}</option>
                              ))
                            }
                          </select>
                        )}
                      </Field>
                    </Form.Group>
                    <ErrorMessage name="agent" component="div" className="text-danger" />

                  </Col>

                </Row>

                <Button type="submit" className="mt-4 btn-success px-4" disabled={isSubmitting}>
                  Submit
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Card.Body>
    </Card>
  </>
}

export default PhoneModel