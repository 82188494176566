import axios from "axios";
import { getItem } from '../util/localStorage'
import { apiUrl } from "../config/config";


export const aiService = {
  login,
  Dashboard,
  ChangeStatus,
  leads,
  makeCall,
  save_availibility,
  get_availibility,
  callListGpt,
  changePassword,
  appointments,
  updateappointments,
  dashboard,
  make_calls, addKnowledgeBase, listKnowledgeBase, configuration,
  getAgents, createAgent, importPhoneNumber, phoneNumbers, updatePhoneNumber,
  deleteAgent, deletePhoneNumber, clearKnowledgeBase
}


const headers = () => {
  let token = getItem("safe_tek_admin_token")


  return { headers: { 'Authorization': `${token}` } }
};

async function login(data) {
  return await axios.post(`${apiUrl}/login/`, data);
}

async function Dashboard() {
  return await axios.get(`${apiUrl}/admin_dashboard`, headers())
}
async function ChangeStatus(id, params) {
  return await axios.put(`${apiUrl}/change_user_status/${id}/`, params, headers())
}

async function makeCall(params) {
  return await axios.post(`${apiUrl}/outgoing/`, params, headers())
}

async function save_availibility(params, id) {
  return await axios.post(`${apiUrl}/edit_availability/${id}/`, params, headers())
}
async function get_availibility() {
  return await axios.get(`${apiUrl}/availability_listing/`, headers())
}
async function callListGpt(params) {
  return await axios.get(`${apiUrl}/call-list-gpt/?limit=${params.limit}&page=${params.page}&filter=${params.filter}`, headers())
}

async function appointments(page, page_size, search) {
  return await axios.get(`${apiUrl}/appointments?page=${page}&page_size=${page_size}&date=${search}`, headers())

}
async function updateappointments(data) {
  return await axios.post(`${apiUrl}/appointments`, data, headers())

}

async function changePassword(data) {
  return await axios.post(`${apiUrl}/change_password/`, data, headers())

}

async function dashboard() {
  return await axios.get(`${apiUrl}/list_counts`, headers())

}
async function leads(params) {
  return await axios.get(`${apiUrl}/contactus_listing?limit=${params.limit}&page=${params.page}&search_query=${params.search}`, headers())
}


async function configuration(method = 'GET', params) {
  if (method == 'GET') {
    return await axios.get(`${apiUrl}/configuration/`, headers())
  }
  return await axios.post(`${apiUrl}/configuration/`, params, headers())
}

async function listKnowledgeBase(params) {
  return await axios.get(`${apiUrl}/list-knowledge-base/?limit=${params.limit}&page=${params.page}`, headers())
}

async function addKnowledgeBase(params) {
  return await axios.post(`${apiUrl}/add-knowledge-base/`, params, headers())
}

async function clearKnowledgeBase(data) {
  return await axios.post(`${apiUrl}/clear-knowledge-base/`, data, headers())
}

async function createAgent(params) {
  return await axios.post(`${apiUrl}/agents/`, params, headers())
}

async function getAgents(params) {
  return await axios.get(`${apiUrl}/agents/`, headers())
}

async function importPhoneNumber(params) {
  return await axios.post(`${apiUrl}/import-phone-number/`, params, headers())
}

async function phoneNumbers(params) {
  return await axios.get(`${apiUrl}/phone-numbers/`, headers())
}

async function updatePhoneNumber(params) {
  return await axios.patch(`${apiUrl}/import-phone-number/`, params, headers())
}

async function deleteAgent(agent_id) {
  return await axios.delete(`${apiUrl}/agents/?id=${agent_id}`, headers())
}

async function deletePhoneNumber(phone_id) {
  return await axios.delete(`${apiUrl}/phone-numbers/?id=${phone_id}`, headers())
}

async function make_calls() {
  // Headers
  const headers = {
    authorization: "sk-86v4a88rl82punek4o5qgt1rk82pttlgo8os5pil5dx4zuokudqpjpggklm57vpj69",
  };

  // Data
  const data = {
    phone_number: "+917696772204",
    task: "Your name is Skyler and you’re a health assistant at PGI.Check what problem patient have and ask to book an appointment if needed",
    voice_id: 0,
    request_data: {
      calling: "Fantastic Airlines",
      bag_claim: "69683",
      airline_code: "UA123",
    },
    reduce_latency: true,
    amd: true,
    webhook: "YOUR-WEBHOOK-HERE",
  };

  // API request
  await axios.post("https://api.bland.ai/call", data, { headers });

}

















